import { FC, useMemo } from 'react';
import ActionPreview from '../../ActionPreview';
import { ActionPreviewBaseProps } from '../ActionBaseProps';

const EmailInputPreview: FC<ActionPreviewBaseProps<string>> = (props) => {
  const { answerOnly, response, data } = props;
  const { question, previewQuestion, previewDescription } = data;

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);
  const answer = useMemo(() => {
    if (!response) {
      return '-';
    }

    return (
      <a href={`mailto:${response}`} className="underline">
        {response}
      </a>
    );
  }, [response]);

  if (answerOnly) {
    return <>{answer}</>;
  }

  return <ActionPreview data-cy="email-input-preview" question={title} description={previewDescription} answer={answer} />;
};

export default EmailInputPreview;
